<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          <span v-if="type == 'EXPORT_DOCUMENTATION'">Export</span>
          <span v-else>Import</span>
          Document Settings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" :loading="saving" @click="saveSettings()">
          <v-icon>save</v-icon>
        </v-btn>
        <v-btn text @click="$emit('close')"> X </v-btn>
      </v-toolbar>
      <v-card-text class="my-0 py-0">
        <v-container>
          <v-row>
            <v-col cols="12" sm="2">
              <v-list dense subheader>
                <v-subheader style="font-size: 16px"> Countries </v-subheader>
                <v-divider></v-divider>
                <v-list-item
                  v-for="country in relationship.organisationRelationshipCountries"
                  :key="country.id"
                  @click="selectCountry(country)"
                  :style="{
                    'border-left':
                      selectedCountry && selectedCountry.id === country.id
                        ? '5px solid var(--v-primary-base)'
                        : '5px solid transparent',
                  }"
                >
                  <v-list-item-action>
                    <v-avatar size="36" v-if="country.countryRegion.iso2">
                      <v-img
                        contain
                        :src="`https://cdn.loglive.io/flags/4x3/${country.countryRegion.iso2.toLowerCase()}.svg`"
                      >
                      </v-img>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ country.countryRegion.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ country.countryRegion.regionCode }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="10">
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :loading="documentLoading"
                    :items="documents"
                    :headers="headers"
                    dense
                    disable-pagination
                    hide-default-footer
                    fixed-header
                    flat
                    height="70vh"
                  >
                    <template v-slot:[`item.quantityType`]="{ item }">
                      <v-chip
                        outlined
                        small
                        class="primaryText--text"
                        v-if="item.quantityType == 'Shipment'"
                      >
                        <v-icon left>directions_boat</v-icon>
                        {{ item.quantityType }}
                      </v-chip>
                      <v-chip
                        outlined
                        small
                        class="primaryText--text"
                        v-else-if="item.quantityType == 'Container'"
                      >
                        <v-icon left color="orange">widgets</v-icon>
                        {{ item.quantityType }}
                      </v-chip>
                      <v-chip
                        outlined
                        small
                        class="primaryText--text"
                        v-else-if="item.quantityType == 'CountContainer'"
                      >
                        <v-icon left color="blue">view_module</v-icon>
                        Per {{ item.count }} Containers
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["updateKey", "relationship", "type"],
  data: () => ({
    documentLoading: false,
    documents: [],
    headers: [
      // {
      //   text: "Action",
      //   value: "action",
      //   align: "center",
      //   width: "20px",
      //   sortable: false,
      // },
      // {
      //   text: "Type",
      //   value: "type",
      //   align: "left",
      // },
      {
        text: "Rank",
        value: "rank",
        align: "center",
      },
      {
        text: "Name",
        value: "name",
        align: "left",
        width: "250px",
      },
      {
        text: "Regulatory Body",
        value: "regulatoryBody.name",
        align: "left",
      },
      {
        text: "Quantity",
        value: "quantityType",
        align: "left",
      },
      {
        text: "Notes",
        value: "comment",
        align: "center",
        sortable: false,
      },
      {
        text: "Special Instruction",
        value: "specialInstruction",
        align: "center",
        sortable: false,
      },
    ],
    saving: false,
    selectedCountry: null,
    settings: {},
  }),
  watch: {
    "selectedCountry.id": {
      immediate: true,
      handler(val) {
        if (val) {
          if (
            this.relationship &&
            this.relationship.organisationRelationshipCountries.length > 0
          ) {
            this.selectCountry(
              this.relationship.organisationRelationshipCountries[0]
            );
          }
          this.getCountryExportDocuments();
        }
      },
    },
    "updateKey": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getSettings();
          if (
            this.relationship
          ){
            this.getCountryExportDocuments();
          }
          
        }
      },
    },
    "relationship.id": {
      immediate: true,
      handler(val) {
        if (val) {
          if (
            this.relationship &&
            this.relationship.organisationRelationshipCountries.length > 0
          ) {
            this.selectCountry(
              this.relationship.organisationRelationshipCountries[0]
            );
          }
          this.getSettings();
        }
      },
    },
  },
  methods: {
    async getCountryExportDocuments() {
      this.documentLoading = true;
      this.documents = await this.$API.getCountryDocuments(
        this.selectedCountry.countryRegionId,
        { type: this.type == 'EXPORT_DOCUMENTATION'?"Export":"Import" }
      );
      this.documentLoading = false;
    },
    async getSettings() {
      try {
        let result = await this.$API.getRelationshipModuleSettings(
          this.relationship.id,
          {
            params: {
              type: this.type
            },
          }
        );
        this.settings = result;
      } catch (error) {
        console.error(error);
      }
    },
    async saveSettings() {
      this.saving = true;
      await this.$API.updateRelationshipModuleSettings(this.relationship.id, {
        type: "BOOKING_COORDINATOR",
        data: this.settings,
      });
      this.$message.success("Settings saved successfully");
      this.saving = false;
    },
    selectCountry(country) {
      this.selectedCountry = country;
    },
  },
};
</script>