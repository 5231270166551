<template>
    <div>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Booking Settings</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon color="primary" :loading="saving" @click="saveSettings()">
                    <v-icon>save</v-icon>
                </v-btn>
                <v-btn text @click="$emit('close')">
                    X
                </v-btn>
            </v-toolbar>
            <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-list dense class="text-left">
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>import_export</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Movement Type</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small :color="settings.movementType == 'EXPORT'
                          ? 'deep-orange'
                          : settings.movementType == 'IMPORT'?
                          'blue darken-2':'green darken-2'
                          ">
                              <v-icon small left>import_export</v-icon>
                              <span style="text-transform: none; color: white">
                                {{ $Format.capitalizeFirstLetter(settings.movementType) }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="settings.movementType = 'EXPORT'">
                              <v-list-item-content>
                                <v-list-item-title>Export</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="settings.movementType = 'IMPORT'">
                              <v-list-item-content>
                                <v-list-item-title>Import</v-list-item-title>
                        
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="settings.movementType = 'LOCAL', settings.modeOfTransport = 'ROAD'">
                              <v-list-item-content>
                                <v-list-item-title>Local</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>airline_stops</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Cargo Service Type</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small color="indigo">
                              <v-icon small left>import_export</v-icon>
                              <span style="text-transform: none; color: white">
                                {{ settings.serviceType }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="settings.serviceType = 'FCL'">
                              <v-list-item-content>
                                <v-list-item-title>FCL</v-list-item-title>
                                <v-list-item-subtitle>Full Container Load</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="settings.serviceType = 'LCL'">
                              <v-list-item-content>
                                <v-list-item-title>LCL</v-list-item-title>
                                <v-list-item-subtitle>Less than Container
                                  Load</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="settings.serviceType = 'BREAKBULK'">
                              <v-list-item-content>
                                <v-list-item-title>Breakbulk</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>category</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Booking Type</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small color="teal">
                              <v-icon small left v-if="settings.type == 'LOGISTICS'">mode_of_travel</v-icon>
                              <v-icon small left v-else-if="settings.type == 'DOCUMENTATION'">article</v-icon>
                              <v-icon small left v-else-if="settings.type == 'HAULAGE'">local_shipping</v-icon>
                              <v-icon small left v-else-if="settings.type == 'MONITORING'">thermostat</v-icon>

                              <span style="text-transform: none; color: white">
                                {{ $Format.capitalizeFirstLetter(settings.type) }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="settings.type = 'LOGISTICS'">
                              <v-list-item-content>
                                <v-list-item-title>Logistics</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="settings.type = 'DOCUMENTATION'">
                              <v-list-item-content>
                                <v-list-item-title>Documentation Only</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="settings.type = 'HAULAGE'">
                              <v-list-item-content>
                                <v-list-item-title>Transport Only</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="settings.type = 'MONITORING'">
                              <v-list-item-content>
                                <v-list-item-title>Monitoring Only</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>mode_of_travel</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Mode of Transport</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small color="blue-grey darken-2">
                              <v-icon small left v-if="settings.modeOfTransport == 'OCEAN'">directions_boat</v-icon>
                              <v-icon small left v-else-if="settings.modeOfTransport == 'AIR'">flight</v-icon>
                              <v-icon small left v-else-if="settings.modeOfTransport == 'RAIL'">train</v-icon>
                              <v-icon small left v-else-if="settings.modeOfTransport == 'ROAD'">local_shipping</v-icon>

                              <span style="text-transform: none; color: white">
                                {{
                                  $Format.capitalizeFirstLetter(settings.modeOfTransport)
                                }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="settings.modeOfTransport = 'OCEAN'">
                              <v-list-item-content>
                                <v-list-item-title>Ocean</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="settings.modeOfTransport = 'AIR'">
                              <v-list-item-content>
                                <v-list-item-title>Air</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="settings.modeOfTransport = 'ROAD'">
                              <v-list-item-content>
                                <v-list-item-title>Road</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="settings.modeOfTransport = 'RAIL'">
                              <v-list-item-content>
                                <v-list-item-title>Rail</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-list dense class="text-left">
                    <!-- <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>description</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Contract Owner</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small :color="settings.movementType == 'EXPORT'
                          ? 'deep-orange'
                          : settings.movementType == 'IMPORT'?
                          'blue darken-2':'green darken-2'
                          ">
                              <v-icon small left>import_export</v-icon>
                              <span style="text-transform: none; color: white">
                                {{ $Format.capitalizeFirstLetter(settings.movementType) }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="settings.movementType = 'EXPORT'">
                              <v-list-item-content>
                                <v-list-item-title>Export</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="settings.movementType = 'IMPORT'">
                              <v-list-item-content>
                                <v-list-item-title>Import</v-list-item-title>
                        
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="settings.movementType = 'LOCAL', settings.modeOfTransport = 'ROAD'">
                              <v-list-item-content>
                                <v-list-item-title>Local</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item> -->
</v-list>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    props: ['updateKey', 'relationship'],
    data: ()=>({
        saving: false,
        settings: {
            movementType: 'EXPORT',
            serviceType: 'FCL',
            type: 'LOGISTICS',
            modeOfTransport: 'OCEAN'
        }
    }),
    watch: {
        'relationship.id': {
            immediate: true,
            handler(val) {
                console.log(val)
                if(val) {
                    this.getSettings();
                }
            }
        }
    },
    methods: {
        async getSettings() {
            try {
                let result = await this.$API.getRelationshipModuleSettings(this.relationship.id, {
                    params: {
                        type: 'BOOKING_COORDINATOR'
                    }
                })
                this.settings = result
            } catch (error) {
                console.error(error);
            }
        },
        async saveSettings(){
            this.saving = true;
            await this.$API.updateRelationshipModuleSettings(this.relationship.id, {
                type: 'BOOKING_COORDINATOR',
                data: this.settings
            })
            this.$message.success('Settings saved successfully')
            this.saving = false
        }
    }
}
</script>