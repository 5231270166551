<template>
  <div class="innerContent"
    style="
      height: calc(100vh);
      width: 100%;
      min-width: 85%;
      max-width: 100%;
      background: var(--v-greyRaised-base) !important;
    ">
        <v-row class="pa-2 mx-1">
          <v-col cols="12" md="4">
            <v-card color="modal" class="my-0 py-0" style="border-radius: 20px">
              <v-card-text class="ma-0 pa-0">
                <v-toolbar flat color="transparent">
                  <v-toolbar-title>
                    <v-avatar
                      size="48"
                      class="mr-2"
                      :color="
                        $store.state.currentOrg.logo ? 'white' : 'secondary'
                      "
                    >
                      <v-img
                        v-if="$store.state.currentOrg.logo"
                        :src="$store.state.currentOrg.logo"
                        alt="Company Logo"
                        contain
                      ></v-img>
                      <h3 v-else>
                        {{ $store.state.currentOrg.name.charAt(0) }}
                      </h3>
                    </v-avatar>
                    {{ $store.state.currentOrg.name }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-divider></v-divider>

                <v-list dense class="ma-0 pa-0" subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Share My Contacts</v-list-item-title>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        class="text-wrap"
                      >
                        This will allow the other party to effectively manage
                        your shipment profiles
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        color="blue"
                        v-model="company.shareContacts"
                        @click="saveRelationship()"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-subheader
                    ><v-icon class="mr-2">person</v-icon> Account
                    Executive</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item
                    id="account-executive"
                    v-if="company.accountExecutive"
                    @click="
                      editAccountManager({
                        name: 'Account Executive',
                        value: 'accountExecutive',
                      })
                    "
                  >
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img
                          v-if="company.accountExecutive.avatar"
                          contain
                          :src="company.accountExecutive.avatar"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ company.accountExecutive.firstname }}
                        {{ company.accountExecutive.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="company.accountExecutive.emailAddress"
                      >
                        <v-icon class="mr-1" small color="grey">email</v-icon>
                        {{ company.accountExecutive.emailAddress }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="company.accountExecutive.mobileNumber"
                      >
                        <v-icon class="mr-1" small color="grey">phone</v-icon>
                        {{ company.accountExecutive.mobileNumber }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-else
                    @click="
                      editAccountManager({
                        name: 'Account Executive',
                        value: 'accountExecutive',
                      })
                    "
                    id="account-executive"
                  >
                    <v-list-item-action>
                      <v-icon color="primary">add_circle_outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        >Add Account Executive</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-subheader
                    ><v-icon class="mr-2">account_circle</v-icon> Account
                    Manager</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item
                    id="account-manager"
                    v-if="company.accountManager"
                    @click="
                      editAccountManager({
                        name: 'Account Manager',
                        value: 'accountManager',
                      })
                    "
                  >
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img
                          v-if="company.accountManager.avatar"
                          contain
                          :src="company.accountManager.avatar"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ company.accountManager.firstname }}
                        {{ company.accountManager.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="company.accountManager.emailAddress"
                      >
                        <v-icon class="mr-1" small color="grey">email</v-icon>
                        {{ company.accountManager.emailAddress }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="company.accountManager.mobileNumber"
                      >
                        <v-icon class="mr-1" small color="grey">phone</v-icon>
                        {{ company.accountManager.mobileNumber }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-else
                    @click="
                      editAccountManager({
                        name: 'Account Manager',
                        value: 'accountManager',
                      })
                    "
                    id="account-manager"
                  >
                    <v-list-item-action>
                      <v-icon color="primary">add_circle_outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Add Account Manager</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-subheader
                    ><v-icon class="mr-2">confirmation_number</v-icon> Booking
                    Coordinator(s)
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      icon
                      @click="
                        addRelationshipRole({
                          name: 'Booking Coordinator',
                          value: 'BOOKING_COORDINATOR',
                        })
                      "
                      ><v-icon>add_circle_outline</v-icon></v-btn
                    >
                  </v-subheader>
                  <v-divider></v-divider>
                  <div :key="userKey">
                    <div v-if="bookingCoordinators.length > 0">
                      <v-list-item
                        id="booking-coordinator"
                        v-for="user in bookingCoordinators"
                        :key="user.id"
                      >
                        <v-list-item-action>
                          <v-avatar size="32">
                            <v-img
                              v-if="user.organisationUser.user.avatar"
                              contain
                              :src="user.organisationUser.user.avatar"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ user.organisationUser.user.firstname }}
                            {{ user.organisationUser.user.surname }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-if="user.organisationUser.user.emailAddress"
                          >
                            <v-icon class="mr-1" small color="grey"
                              >email</v-icon
                            >
                            {{ user.organisationUser.user.emailAddress }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="user.organisationUser.user.mobileNumber"
                          >
                            <v-icon class="mr-1" small color="grey"
                              >phone</v-icon
                            >
                            {{ user.organisationUser.user.mobileNumber }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>

                    <v-list-item v-else id="booking-coordinator">
                      <v-list-item-content class="text-center">
                        <span style="font-size: 12px; color: grey"
                          >No Booking Coordinators Listed</span
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </div>

                  <v-divider></v-divider>
                  <v-subheader>
                    <v-icon class="mr-2">article</v-icon> Documentation
                    Coordinator(s)
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      icon
                      @click="
                        addRelationshipRole({
                          name: 'Documentation Coordinator',
                          value: 'DOCUMENTATION_COORDINATOR',
                        })
                      "
                    >
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                  </v-subheader>
                  <v-divider></v-divider>
                  <div>
                    <div v-if="documentationCoordinators.length > 0">
                      <v-list-item
                        id="documentation-coordinator"
                        v-for="user in documentationCoordinators"
                        :key="user.id"
                      >
                        <v-list-item-action>
                          <v-avatar size="32">
                            <v-img
                              v-if="user.organisationUser.user.avatar"
                              contain
                              :src="user.organisationUser.user.avatar"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ user.organisationUser.user.firstname }}
                            {{ user.organisationUser.user.surname }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-if="user.organisationUser.user.emailAddress"
                          >
                            <v-icon class="mr-1" small color="grey"
                              >email</v-icon
                            >
                            {{ user.organisationUser.user.emailAddress }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="user.organisationUser.user.mobileNumber"
                          >
                            <v-icon class="mr-1" small color="grey"
                              >phone</v-icon
                            >
                            {{ user.organisationUser.user.mobileNumber }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                    <div v-else>
                      <v-list-item-content class="text-center">
                        <span style="font-size: 12px; color: grey">
                          No Document Coordinators Listed
                        </span>
                      </v-list-item-content>
                    </div>
                  </div>

                  <v-divider></v-divider>

                  <v-subheader class="mb-0 pb-0">
                    <v-icon class="mr-2">groups</v-icon> Assigned Teams
                    <v-spacer></v-spacer>
                    <v-btn
                      id="edit-teams"
                      icon
                      color="primary"
                      @click="editTeams"
                      ><v-icon small>edit</v-icon></v-btn
                    >
                  </v-subheader>
                  <v-list dense class="pl-2 mt-0 mb-2">
                    <v-list-item
                      v-for="team in teams"
                      :key="team.id"
                      :style="{
                        'border-left': `5px solid ${team.team.colour}`,
                        height: '40px',
                      }"
                      class="my-1"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ team.team.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle :key="refreshTeamCount">
                          {{ getTeamMemberCount(team.team.id) }} Member(s)
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="teams.length == 0">
                      <v-list-item-content class="text-center">
                        <span style="color: grey">No teams listed</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- <v-divider vertical></v-divider> -->

          <v-col cols="12" md="4">
            <v-card color="modal" class="my-0 py-0" style="border-radius: 20px">
              <v-card-text class="ma-0 pa-0">
                <v-toolbar flat color="transparent">
                  <v-toolbar-title>
                    <v-avatar
                      size="48"
                      class="mr-2"
                      :color="relatedOrglogo ? 'white' : 'secondary'"
                    >
                      <v-img
                        v-if="relatedOrglogo"
                        :src="relatedOrglogo"
                        alt="Company Logo"
                        contain
                      ></v-img>
                      <h3 v-else-if="relatedOrgName">
                        {{ relatedOrgName.charAt(0) }}
                      </h3>
                    </v-avatar>
                    {{ relatedOrgName }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-divider></v-divider>

                <!-- Inverse Company Logo and Name -->
                <!-- Inverse Company Details -->
                <v-list dense class="ma-0 pa-0" subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Shared Contacts</v-list-item-title>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        class="text-wrap"
                      >
                        This will allow the other party to effectively manage
                        your shipment profiles
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon :color="company.shareContacts ? 'green' : 'red'">
                        {{ company.shareContacts ? "check" : "close" }}
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-subheader
                    ><v-icon class="mr-2">person</v-icon> Account
                    Executive</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item
                    id="inverse-account-executive"
                    v-if="accountExecutive"
                  >
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img
                          v-if="accountExecutive.avatar"
                          contain
                          :src="accountExecutive.avatar"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ accountExecutive.firstname }}
                        {{ accountExecutive.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="accountExecutive.emailAddress"
                      >
                        <v-icon class="mr-1" small color="grey">email</v-icon>
                        {{ accountExecutive.emailAddress }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="accountExecutive.mobileNumber"
                      >
                        <v-icon class="mr-1" small color="grey">phone</v-icon>
                        {{ accountExecutive.mobileNumber }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else id="inverse-account-executive">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No Account Executive Listed</span
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-subheader
                    ><v-icon class="mr-2">account_circle</v-icon> Account
                    Manager</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item
                    id="inverse-account-manager"
                    v-if="accountManager"
                  >
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img
                          v-if="accountManager.avatar"
                          contain
                          :src="accountManager.avatar"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ accountManager.firstname }}
                        {{ accountManager.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="accountManager.emailAddress">
                        <v-icon class="mr-1" small color="grey">email</v-icon>
                        {{ accountManager.emailAddress }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="accountManager.mobileNumber">
                        <v-icon class="mr-1" small color="grey">phone</v-icon>
                        {{ accountManager.mobileNumber }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else id="inverse-account-manager">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No Account Manager Listed</span
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-subheader
                    ><v-icon class="mr-2">article</v-icon> Documentation
                    Coordinator(s)</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item id="documentation-coordinator">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No Documentation Coordinators Listed</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card
              color="modal"
              class="my-0 py-0"
              style="border-radius: 20px"
              :loading="loadingRelationshipFunctions"
            >
              <v-card-text class="ma-0 pa-0">
                <v-toolbar flat color="transparent">
                  <v-toolbar-title>
                    <v-avatar size="48" class="mr-2" :color="'secondary'">
                      <v-icon>manage_accounts</v-icon>
                    </v-avatar>
                    Functions Provided
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle style="font-size: 12px">
                  These are functions that your company will fulfill using the
                  tools & modules within LogLive
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-list dense subheader>
                  <v-list-item v-for="(role, index) in roles" :key="index">
                    <v-list-item-action>
                      <v-icon color="grey" small>{{ role.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ role.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="text-wrap"
                        v-if="role.description"
                      >
                        {{ role.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <!-- <v-list-item-action>
                      <v-btn
                        small
                        icon
                        v-if="activeFunctions.includes(role.value)"
                        @click="editModuleSettings(role.value)"
                      >
                        <v-icon small> settings </v-icon>
                      </v-btn>
                    </v-list-item-action> -->
                    <v-list-item-action>
                      <v-switch
                        v-model="activeFunctions"
                        :value="role.value"
                        @change="saveActiveFunction"
                        color="success"
                        inset
                        dense
                        :error="!activeFunctions.includes(role.value)"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="roles.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey">
                        Your subscriptions do not have any linked functions
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-toolbar flat color="transparent">
                  <v-toolbar-title>
                    <v-avatar size="48" class="mr-2" :color="'secondary'">
                      <v-icon>share</v-icon>
                    </v-avatar>
                    Shareable Modules
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle style="font-size: 12px">
                  You will give this company access to the following modules,
                  allowing them to interact with you in the various sections
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-list dense subheader>
                  <v-list-item v-for="available in modules" :key="available.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ available.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-wrap">
                        {{ available.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        v-model="sharedModules"
                        :value="available.id"
                        @change="saveSharedModule"
                        color="success"
                        inset
                        dense
                        :error="!sharedModules.includes(available.id)"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="modules.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey">
                        Your subscriptions do not have any shareable modules
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
    <!-- User manager modal -->
    <v-dialog
      v-model="accountManagerModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card :loading="loadingUsers">
        <v-toolbar flat color="transparent">
          <v-toolbar-title v-if="editUserType">
            <v-icon class="mr-2">person</v-icon>
            {{ editUserType.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="accountManagerModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <el-input
            suffix-icon="el-icon-search"
            v-model="userSearch"
            clearable
            placeholder="Search"
          >
          </el-input>
          <v-list dense style="max-height: 70vh; overflow-y: auto">
            <v-list-item
              v-for="user in filterOrgUsers"
              :key="user.id"
              @click="updateAccountManager(user)"
            >
              <v-list-item-action>
                <v-avatar
                  size="40"
                  :color="user.user.avatar ? 'white' : 'secondary'"
                >
                  <v-img
                    v-if="user.user.avatar"
                    contain
                    :src="user.user.avatar"
                  ></v-img>
                  <h2 v-else color="white">
                    {{ user.user.firstname.charAt(0) }}
                  </h2>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  >{{ user.user.firstname }}
                  {{ user.user.surname }}</v-list-item-title
                >
                <v-list-item-subtitle v-if="user.position">
                  <v-icon small color="secondary" class="mr-2">label</v-icon>
                  {{ user.position }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="user.user.emailAddress">
                  <v-icon small color="grey" class="mr-2">email</v-icon>
                  {{ user.user.emailAddress }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="user.user.mobileNumber">
                  <v-icon small color="grey" class="mr-2">phone</v-icon>
                  {{ user.user.mobileNumber }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Team Modal -->
    <v-dialog
      v-model="teamModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title
            ><v-icon class="mr-2">groups</v-icon>Assigned Teams</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="saveTeams" color="primary" :loading="savingTeams"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="teamModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list class="mt-0 mb-2">
            <v-list-item
              v-for="team in allTeams"
              :key="team.id"
              :style="{
                'border-left': `5px solid ${team.colour}`,
                height: '50px',
              }"
              class="my-1"
            >
              <v-list-item-content>
                <v-list-item-title>{{ team.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  color="primary"
                  v-model="selectedTeams"
                  :value="team.id"
                ></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Role Manager Modal -->
    <v-dialog
      v-model="roleManagerModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="loadingUserRole">
        <v-toolbar flat>
          <v-toolbar-title>{{
            userItem ? userItem.title : ""
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              (roleManagerModal = false),
                (userItem = null),
                (roleUserSearch = null)
            "
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <el-input
            suffix-icon="el-icon-search"
            v-model="roleUserSearch"
            clearable
            placeholder="Search"
          >
          </el-input>
          <v-list dense style="max-height: 70vh; overflow-y: auto">
            <v-list-item
              v-for="user in filterRoleUsers"
              :key="user.id"
              @click="addRoleUser(user)"
            >
              <v-list-item-action>
                <v-avatar
                  size="40"
                  :color="user.user.avatar ? 'white' : 'secondary'"
                >
                  <v-img
                    v-if="user.user.avatar"
                    contain
                    :src="user.user.avatar"
                  ></v-img>
                  <h2 v-else color="white">
                    {{ user.user.firstname.charAt(0) }}
                  </h2>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  >{{ user.user.firstname }}
                  {{ user.user.surname }}</v-list-item-title
                >
                <v-list-item-subtitle v-if="user.position">
                  <v-icon small color="secondary" class="mr-2">label</v-icon>
                  {{ user.position }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="user.user.emailAddress">
                  <v-icon small color="grey" class="mr-2">email</v-icon>
                  {{ user.user.emailAddress }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="user.user.mobileNumber">
                  <v-icon small color="grey" class="mr-2">phone</v-icon>
                  {{ user.user.mobileNumber }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="moduleSettingsModal" width="1500px">
      <v-card>
        <BookingSettings
          v-if="moduleSettingType == 'BOOKING_COORDINATOR'"
          @close="moduleSettingsModal = false"
          :relationship="company"
        />
        <DocumentSettings
          v-else-if="moduleSettingType == 'EXPORT_DOCUMENTATION' || moduleSettingType == 'IMPORT_DOCUMENTATION'"
          @close="moduleSettingsModal = false"
          :updateKey="updateKey"
          :type="moduleSettingType"
          :relationship="company"
        />
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import * as axios from "axios";
import * as CurrencyCodes from "currency-codes";
import BookingSettings from "./RelationshipModuleSettings/BookingSettings.vue";
import DocumentSettings from "./RelationshipModuleSettings/DocumentSettings.vue";
export default {
  props: ["company", "teams"],
  components: { BookingSettings, DocumentSettings },
  data: () => ({
    accountManagerModal: false,
    activeFunctions: [],
    allTeams: [],
    billingItems: [],
    caProductModal: false,
    currencies: CurrencyCodes.codes(),
    configuration: {},
    editAccountReference: false,
    editPaymentTerms: false,

    // New Inverse data fields
    accountManager: null,
    accountExecutive: null,

    // Logo and Name for Inverse company
    relatedOrgName: null,
    relatedOrglogo: null,
    roles: [],
    shareContacts: false,
    editUserType: null,
    financeChargeItems: [],
    headers: [
      {
        text: "Name",
        value: "user.firstname",
        align: "center",
      },
      {
        text: "Surname",
        value: "user.surname",
        align: "center",
      },
      {
        text: "Email",
        value: "user.emailAddress",
        align: "center",
      },
      {
        text: "Position",
        value: "position",
        align: "center",
      },
      {
        text: "Office",
        value: "organisationAddress.alias",
        align: "center",
      },
    ],
    loadingSetting: false,
    loadingSharedModules: false,
    loadingRelationshipFunctions: false,
    loadingUsers: false,
    loadingUserRole: false,
    modules: [],
    moduleSettingsModal: false,
    moduleSettingType: null,
    refreshTeamCount: 2000,
    roleManagerModal: false,
    roleUserSearch: null,
    savingClientStatus: false,
    savingRelationship: false,
    savingSettings: false,
    savingTeams: false,
    selectedCountry: null,
    selectedTab: 0,
    selectedTeams: [],
    setting: [],
    sharedModules: [],
    teamModal: false,
    updateKey: 100,
    userItem: {},
    users: [],
    userKey: 1000,
    userSearch: null,
  }),
  watch: {
    "company.organisationRelationshipCountries": {
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          this.selectedCountry = val[0].countryRegion.iso2;
          this.getSetting();
        }
      },
    },
    selectedTab(val) {
      this.getSetting();
    },
  },
  computed: {
    bookingCoordinators() {
      let users = this.company.organisationRelationshipUsers.filter(
        (x) => x.role == "BOOKING_COORDINATOR"
      );
      return users;
    },
    documentationCoordinators() {
      let users = this.company.organisationRelationshipUsers.filter(
        (x) => x.role == "DOCUMENTATION_COORDINATOR"
      );
      return users;
    },
    filterOrgUsers() {
      let result = this.users;
      if (this.userSearch) {
        result = result.filter(
          (x) =>
            x.user.firstname
              .toLowerCase()
              .includes(this.userSearch.toLowerCase()) ||
            x.user.surname
              .toLowerCase()
              .includes(this.userSearch.toLowerCase()) ||
            x.user.emailAddress
              .toLowerCase()
              .includes(this.userSearch.toLowerCase())
        );
      }
      return result;
    },
    filterRoleUsers() {
      let result = this.users;
      if (this.userItem) {
        let existingUsers = this.company.organisationRelationshipUsers
          .filter((x) => x.role == this.userItem.role)
          .map((x) => x.organisationUserId);
        result = result.filter((x) => !existingUsers.includes(x.id));
      }
      if (this.roleUserSearch) {
        result = result.filter(
          (x) =>
            x.user &&
            ((x.user.firstname &&
              x.user.firstname
                .toLowerCase()
                .includes(this.roleUserSearch.toLowerCase())) ||
              (x.user.surname &&
                x.user.surname
                  .toLowerCase()
                  .includes(this.roleUserSearch.toLowerCase())) ||
              (x.user.emailAddress &&
                x.user.emailAddress
                  .toLowerCase()
                  .includes(this.roleUserSearch.toLowerCase())) ||
              (x.position &&
                x.position
                  .toLowerCase()
                  .includes(this.roleUserSearch.toLowerCase())))
        );
      }
      return result;
    },
  },
  async created() {
    this.getOrganisationUsers();
    this.getTeams();
    this.getShareableModules();
    this.getRelationshipFunctions();
    this.roles = await this.$API.getAvailableRoles();
    //Get the shared Contact status
    this.checkSharedContacts();

    // Fetch the inverse company logo and name
    const id = this.company.id;
    this.getInverseLogo(id);

    // Fetch inverse users data when component is created
    this.getInverseUsers(id);
  },
  methods: {
    async addRoleUser(user) {
      this.loadingUserRole = true;
      let obj = {
        organisationUser: user,
        role: this.userItem.role,
        organisationRelationshipId: this.company.id,
        organisationUserId: user.id,
      };
      let result = await this.$API.addOrganisationRelationshipUser(obj);
      result.organisationUser = user;
      this.company.organisationRelationshipUsers.push(result);
      this.userKey++;
      this.roleManagerModal = false;
      this.loadingUserRole = false;
    },
    editAccountManager(type) {
      this.editUserType = type;
      this.accountManagerModal = true;
    },
    editModuleSettings(type) {
      this.moduleSettingType = type;
      this.moduleSettingsModal = true;
      this.updateKey ++
    },
    editRelationshipRole(type) {
      this.editUserType = type;
      this.roleManagerModal = true;
    },
    addRelationshipRole(type) {
      this.userItem = {
        title: type.name,
        role: type.value,
      };
      this.roleManagerModal = true;
    },
    editTeams() {
      this.selectedTeams = this.teams.map((team) => team.teamId);
      this.teamModal = true;
    },
    async getOrganisationUsers() {
      this.loadingUsers = true;
      this.users = await this.$API.getOrganisationUsers();
      this.loadingUsers = false;
    },
    async getSetting() {
      this.loadingSetting = true;
      this.setting = await this.$API.getOrganisationRelationshipCountrySetting(
        this.company.id,
        this.selectedCountry
      );
      let selectedSetting = this.company.organisationRelationshipCountries.find(
        (x) => x.countryRegion.iso2 == this.selectedCountry
      );
      if (selectedSetting.url) {
        let configuration = await axios.get(selectedSetting.url);
        this.configuration = configuration.data;
      }
      this.loadingSetting = false;
    },
    async getShareableModules() {
      this.loadingSharedModules = true;
      this.modules = await this.$API.getShareableModules();
      this.sharedModules = await this.$API.getSharedModules(
        this.company.relatedOrganisationId
      );
      this.loadingSharedModules = false;
    },
    async getRelationshipFunctions() {
      this.loadingRelationshipFunctions = true;
      this.activeFunctions = await this.$API.getRelationshipFunctions(
        this.company.id
      );
      this.loadingRelationshipFunctions = false;
    },
    async saveActiveFunction() {
      await this.$API.setRelationshipFunctions(
        this.company.id,
        this.activeFunctions
      );
      this.$message.success("Successfully updated active functions!");
    },
    async saveSharedModule() {
      await this.$API.setSharedModule(
        this.company.relatedOrganisationId,
        this.sharedModules
      );
      this.$message.success("Successfully updated shared modules!");
    },
    async getTeams() {
      this.allTeams = await this.$API.getTeams();
      this.refreshTeamCount++;
    },
    getTeamMemberCount(id) {
      let result = this.allTeams.find((x) => x.id == id);
      return result ? result.userTeams.length : 0;
    },
    async saveRelationship() {
      this.savingRelationship = true;

      if (!this.company.isCustomer && !this.company.isSupplier) {
        this.company.isCustomer = true;
      }
      this.company.accountManagerId = this.company.accountManager
        ? this.company.accountManager.id
        : null;
      await this.$API.updateRelation(this.company);
      this.$message.success("Successfully Updated!");
      this.$emit("refresh", this.company);
      this.savingRelationship = false;
      this.editPaymentTerms = false;
      this.editAccountReference = false;
    },
    async saveTeams() {
      this.savingTeams = true;
      let create = this.selectedTeams.filter(
        (x) => !this.teams.map((y) => y.teamId).includes(x)
      );
      let remove = this.teams.filter(
        (x) => !this.selectedTeams.includes(x.teamId)
      );
      if (create.length > 0) {
        await this.$API.createRelationshipTeam({
          batch: create.map((x) => ({
            teamId: x,
            organisationRelationshipId: this.company.id,
          })),
        });
      }
      for (let j = 0; j < remove.length; j++) {
        let relationshipteamId = this.teams.find(
          (x) => x.teamId == remove[j].teamId
        ).id;
        await this.$API.updateRelationshipTeam({
          id: relationshipteamId,
          isActive: false,
          isDeleted: true,
        });
      }
      this.$emit("refreshTeams");
      this.savingTeams = false;
      this.teamModal = false;
    },
    async saveSettings() {
      this.savingSettings = true;
      let selectedSetting = this.company.organisationRelationshipCountries.find(
        (x) => x.countryRegion.iso2 == this.selectedCountry
      );
      await this.$API.saveOrganisationCountrySettings({
        id: selectedSetting.id,
        settings: this.configuration,
      });
      this.$message.success("Successfully updated settings!");
      this.savingSettings = false;
    },

    // New method for fetching inverse data
    async getInverseUsers(id) {
      if (!id) {
        console.error("No ID provided for getInverseUsers");
        return;
      }
      this.loadingUsers = true;
      try {
        const response = await this.$API.getSelectedCustomerRelationship(id);
        const inverseData = response.inverse;
        this.accountManager = inverseData.accountManager;
        this.accountExecutive = inverseData.accountExecutive;
      } catch (error) {
        console.error("Failed to fetch inverse users:", error);
        this.accountManager = null;
        this.accountExecutive = null;
      } finally {
        this.loadingUsers = false;
      }
    },

    // Method for retrieving inverse company name and logo
    async getInverseLogo(id) {
      if (!id) {
        console.error("No ID provided for getInverseLogo");
        return;
      }
      this.loadingUsers = true;
      try {
        const response = await this.$API.getSelectedCustomerRelationship(id);
        const relatedOrganisationData = response.relatedOrganisation;
        this.relatedOrglogo = relatedOrganisationData.logo;
        this.relatedOrgName = relatedOrganisationData.name;
      } catch (error) {
        console.error(
          "Failed to fetch inverse organisation name and logo:",
          error
        );
        this.relatedOrglogo = null;
        this.relatedOrgName = null;
      } finally {
        this.loadingUsers = false;
      }
    },

    updateAccountManager(user) {
      this.company[this.editUserType.value] = user.user;
      this.company[this.editUserType.value + "Id"] = user.userId;
      this.$emit("refresh", this.company);
      this.saveRelationship();
      this.accountManagerModal = false;
    },
    async updateClientStatus(status) {
      this.savingClientStatus = true;
      this.company.clientStatus = status;
      await this.saveRelationship();
      this.savingClientStatus = false;
    },

    //Get Shared Contact Method
    async checkSharedContacts() {
      try {
        let obj = { orgId: this.company.id };
        const response = await this.$API.getSharedContacts({ params: obj });
        this.company.shareContacts = response.isShared;
        // this.$set(this.company, 'shareContacts', response.isShared);
        console.log("Shared contacts status:", this.company.shareContacts);
      } catch (error) {
        console.error("Error checking shared contacts status:", error);
      }
    },
  },
};
</script>

<style scoped>
.v-divider.vertical {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  height: auto;
  align-self: stretch;
}
</style>
